import React, { createContext, useContext } from "react";
import en from "./en";
import ru from "./ru";
import { useObservable } from "rxjs-hooks";
import getUserLocale from "get-user-locale";
import { ReplaySubject } from "rxjs";

const locales = {
  en,
  ru,
};

const LocaleContext = createContext(en);

export const locale$ = new ReplaySubject();
locale$.next(getUserLocale().slice(0, 2));

locale$.subscribe((locale) => {
  if (locale === "ru") {
    document.title = "Postme Web - планируйте посты для Инстаграма";
    return;
  }
  document.title = "Postme Web - plan your Instagram posts";
});

export const ApplyLocale = ({ children }) => {
  const current = useObservable(() => locale$);
  if (!current) return null;
  return (
    <LocaleContext.Provider value={locales[current] || locales["en"]}>
      {children}
    </LocaleContext.Provider>
  );
};

export function useLocaleContext() {
  return useContext(LocaleContext);
}

export default LocaleContext;
