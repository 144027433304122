import React, { useState, useRef } from "react";
import Uploader from "../Uploader/Uploader";
import ImageGallery from "react-image-gallery";
import FormData from "form-data";
import { BehaviorSubject, Subject, of } from "rxjs";
import { useObservable, useEventCallback } from "rxjs-hooks";
import { useLocaleContext } from "../../locales";
import { Spinner } from "../../components/Spinner/Spinner";
import { withPending } from "../../utils/rx";
import { withLatestFrom, mergeMap } from "rxjs/operators";
import config from "../../config";
import closeIcon from "../../assets/editor/close.svg";
import "./styles.css";
import { getItems } from "../../pages/Posts/Posts";
import { logEvent } from "../../state/amplitude";

export const newPostEditorIsOpened$ = new Subject();
export const updatePostEditorIsOpened$ = new Subject();
export const updatePostId$ = new BehaviorSubject();
export const updatePostText$ = new BehaviorSubject();
export const updatePostImages$ = new BehaviorSubject();

function Button({ className, disabled, handleClick, label }) {
  return (
    <button disabled={disabled} onClick={handleClick} className={className}>
      {disabled ? <Spinner classes="text-white" /> : null}
      {label}
    </button>
  );
}

export function NewPostEditor() {
  const locale = useLocaleContext();
  const isOpened = useObservable(() => newPostEditorIsOpened$);

  const [images, setImages] = useState([]);
  const [text, setText] = useState();

  const [postCallback, postResult] = useEventCallback(
    (event$, state$, inputs$) =>
      event$.pipe(
        withLatestFrom(inputs$),
        mergeMap(([, [text, images]]) => {
          const formData = new FormData();
          images.forEach((item) => formData.append("files", item.file));
          if (text) {
            formData.append("text", text);
          }
          return withPending(
            fetch(`${config.API_URL}/items`, {
              method: "post",
              body: formData,
            }).then((res) => res.json())
          ).pipe(
            mergeMap((res) => {
              if (res && res.result && res.result.status === "success") {
                logEvent("Editor_Saved", {
                  itemId: res.result.data.id,
                });
                newPostEditorIsOpened$.next(false);
                setImages([]);
                setText();
                return getItems();
              }
              return of(res);
            })
          );
        })
      ),
    undefined,
    [text, images]
  );

  if (!isOpened) return null;
  return (
    <Editor
      title={locale.editor.newPost}
      handleClick={postCallback}
      handleClose={() => newPostEditorIsOpened$.next(false)}
      handleInput={(event) => setText(event.target.innerText)}
      handleImageUploadingChange={(imageList, addUpdateIndex) => {
        setImages(imageList);
      }}
      text={text}
      images={images}
      fetchResult={postResult}
    />
  );
}

export function UpdatePostEditor() {
  const locale = useLocaleContext();
  const isOpened = useObservable(() => updatePostEditorIsOpened$);
  const id = useObservable(() => updatePostId$);
  const text = useObservable(() => updatePostText$);
  const images = useObservable(() => updatePostImages$);

  const [postCallback, postResult] = useEventCallback(
    (event$, state$, inputs$) =>
      event$.pipe(
        withLatestFrom(inputs$),
        mergeMap(([, [text, images, id]]) => {
          const formData = new FormData();
          images.forEach((item) => formData.append("files", item.file));
          if (text) {
            formData.append("text", text);
          }
          return withPending(
            fetch(`${config.API_URL}/items/${id}`, {
              method: "PATCH",
              body: formData,
            }).then((res) => res.json())
          ).pipe(
            mergeMap((res) => {
              if (res && res.result && res.result.status === "success") {
                logEvent("Editor_Saved", {
                  itemId: id,
                });
                updatePostEditorIsOpened$.next(false);
                return getItems();
              }
              return of(res);
            })
          );
        })
      ),
    undefined,
    [text, images, id]
  );

  if (!isOpened) return null;
  return (
    <Editor
      title={locale.updatePostEditor.instagramPost}
      handleClick={postCallback}
      handleClose={() => updatePostEditorIsOpened$.next(false)}
      handleInput={(event) => updatePostText$.next(event.target.innerText)}
      handleImageUploadingChange={(imageList, addUpdateIndex) => {
        updatePostImages$.next(imageList);
      }}
      text={text}
      images={images}
      fetchResult={postResult}
    />
  );
}

export function Editor({
  title,
  handleClose,
  handleInput,
  handleImageUploadingChange,
  text,
  images,
  handleClick,
  fetchResult,
}) {
  const locale = useLocaleContext();
  const ref = useRef();
  return (
    <div className="fixed w-full h-full bg-black bg-opacity-25 z-30">
      <div className="absolute right-0 w-9/12 h-full bg-white">
        <div className="m-3">
          <div className="flex border-b items-center">
            <p className="flex-grow text-2xl font-extrabold ml-2 pb-2">
              {title}
            </p>
            <div>
              <button
                className="flex text-gray-500 text-sm font-semibold items-center focus:outline-none"
                onClick={handleClose}
              >
                {locale.editor.close}
                <img className="ml-2 w-3" src={closeIcon} alt="closeIcon" />
              </button>
            </div>
          </div>
          <div className="flex w-full items-start mt-3">
            <div className="flex-grow mr-3">
              <div
                contentEditable
                placeholder={locale.editor.yourPostsText}
                className="whitespace-pre-wrap w-full h-full break-all editor p-2 text-sm"
                onBlur={handleInput}
              >
                {text}
              </div>
            </div>
            <div className="flex flex-col">
              <div>
                <Uploader
                  files={images}
                  dataURLKey="files"
                  handleChange={(items) => {
                    handleImageUploadingChange(items);
                    logEvent("Editor_Media_added", {
                      mediaCount: items.length,
                    });
                  }}
                  accept="image/*, video/*"
                >
                  {() => (
                    <div>
                      <ImageGallery
                        ref={ref}
                        showBullets={true}
                        showNav={false}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        renderItem={(item) => {
                          return item.original.includes("video") ? (
                            <video
                              style={{
                                borderRadius: "3px",
                                width: "9rem",
                                height: "9rem",
                                minWidth: "200px",
                                objectFit: "cover",
                              }}
                              muted
                              autoPlay
                            >
                              <source src={item.original} />
                            </video>
                          ) : (
                            <div
                              className="mb-2 w-36 h-36 rounded"
                              style={{
                                minWidth: "200px",
                                background: `url(${item.original}) no-repeat center`,
                                backgroundSize: "cover",
                              }}
                            />
                          );
                        }}
                        items={images.map((image, index) => ({
                          original: image.dataURL,
                          index,
                        }))}
                      />
                      {images && !!images.length && (
                        <button
                          onClick={() => {
                            const nextImages = images.slice();
                            nextImages.splice(ref.current.getCurrentIndex(), 1);
                            handleImageUploadingChange(nextImages);
                            logEvent("Editor_Media_deleted");
                          }}
                          style={{
                            minWidth: "200px",
                          }}
                          className="my-2 py-2 px-4 border border-opacity-5 hover:bg-white text-sm font-medium rounded-md bg-gray-50 focus:outline-none"
                        >
                          {locale.editor.delete}
                        </button>
                      )}
                    </div>
                  )}
                </Uploader>
              </div>
              <Button
                disabled={fetchResult && fetchResult.pending}
                handleClick={handleClick}
                label={locale.editor.save}
                className="mt-2 py-2 px-4 flex justify-center border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none active:bg-blue-700 transition duration-150 ease-in-out"
              />
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
