import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { SignIn } from "../pages/SignIn/SignIn";
import { Profile } from "../pages/Profile/Profile";
import { Posts } from "../pages/Posts/Posts";
import { Layout } from "../components/Layout/Layout";
import { UserAuthenticated } from "../components/UserAuthenticated/UserAuthenticated";

export function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/signIn" component={SignIn} />
        <Route path="*">
          <UserAuthenticated>
            <Layout>
              <Switch>
                <Route path="/profile" component={Profile} />
                <Route path="/" component={Posts} />
              </Switch>
            </Layout>
          </UserAuthenticated>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
