import { ReplaySubject } from "rxjs";
import { withPending } from "../utils/rx";
import config from "../config";
import { mergeMap, filter } from "rxjs/operators";

export const user$ = new ReplaySubject();
export const subscriptions$ = new ReplaySubject();

withPending(
  fetch(`${config.API_URL}/user`).then((res) => res.json())
).subscribe((res) => user$.next(res));

user$
  .pipe(
    filter((item) => item && item.result && item.result.data),
    mergeMap(() =>
      withPending(
        fetch(`${config.API_URL}/subscriptions`).then((res) => res.json())
      )
    )
  )
  .subscribe((res) => subscriptions$.next(res));
