import React, { createRef, forwardRef } from "react";
import { useLocaleContext } from "../../locales";
import { readFile } from "../../utils";

const InputFile = forwardRef(({ accept, name, handleChange }, ref) => {
  return (
    <input
      style={{
        opacity: "0",
        position: "absolute",
        zIndex: "-1",
      }}
      type="file"
      ref={ref}
      name={name}
      multiple={true}
      onChange={handleChange}
      onClick={(e) => {
        // Fixes https://github.com/JakeHartnell/react-images-upload/issues/55
        e.target.value = null;
      }}
      accept={accept}
    />
  );
});

function Uploader({ accept, children, files, handleChange }) {
  const locale = useLocaleContext();
  const addRef = createRef();
  return (
    <div>
      {children()}
      <button
        type="button"
        className="py-2 px-4 border border-opacity-5 hover:bg-white text-sm font-medium rounded-md bg-gray-50 focus:outline-none"
        style={{
          minWidth: "200px",
        }}
        onClick={() => addRef.current.click()}
      >
        {locale.uploader.addMedia}
      </button>
      <InputFile
        accept={accept}
        name="add"
        ref={addRef}
        handleChange={async (e) => {
          const nextFiles = await Promise.all(
            Object.values(e.target.files).map(readFile)
          );
          handleChange([...files, ...nextFiles]);
        }}
      />
    </div>
  );
}

export default Uploader;
