export function readFile(file) {
  var reader = new FileReader();
  return new Promise((resolve) => {
    reader.addEventListener("load", () =>
      resolve({
        file,
        dataURL: String(
          reader.result.replace(
            ";base64",
            `;name=${encodeURIComponent(file.name)};base64`
          )
        ),
      })
    );
    reader.readAsDataURL(file);
  });
}
