import React from "react";
import {
  NewPostEditor,
  UpdatePostEditor,
} from "../../components/Editor/Editor";
import { Menu } from "../../components/Menu/Menu";

export function Layout({ children }) {
  return (
    <div className="flex">
      <NewPostEditor />
      <UpdatePostEditor />
      <Menu />
      <div
        style={{
          minHeight: "100vh",
          marginLeft: "240px",
        }}
      >
        {children}
      </div>
    </div>
  );
}
