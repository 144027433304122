import React from "react";
import * as R from "ramda";
import { useObservable } from "rxjs-hooks";
import { SignIn } from "../../pages/SignIn/SignIn";
import { user$ } from "../../state/user";

export function UserAuthenticated({ children }) {
  const user = useObservable(() => user$);
  if (!user) return null;
  if (user.pending) return null;
  return R.path(["result", "status"], user) === "success" ? (
    children
  ) : (
    <SignIn />
  );
}
