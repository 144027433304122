import { Observable } from "rxjs";

export const withPending = (promise) =>
  Observable.create((observer) => {
    observer.next({ pending: true });
    promise
      .then((result) => {
        observer.next({ done: true, result });
        observer.complete();
      })
      .catch((error) => {
        observer.next({ done: true, error });
        observer.complete();
      });
  });
