import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useLocaleContext } from "../../locales";
import { newPostEditorIsOpened$ } from "../Editor/Editor";
import { logEvent } from "../../state/amplitude";
import config from "../../config";
import newIcon from "../../assets/menu/new.svg";
import postsIcon from "../../assets/menu/posts.svg";
import profileIcon from "../../assets/menu/profile.svg";
import exitIcon from "../../assets/menu/exit.svg";

function MenuItem({ icon, text, isMatch, handleClick }) {
  return (
    <div className={`flex ${isMatch ? "bg-gray-200" : ""} h-12 `}>
      <button
        onClick={handleClick}
        className="flex items-center w-full hover:bg-gray-200 focus:outline-none"
      >
        <div className={`w-1 h-full ${isMatch ? "bg-blue-600" : ""}`} />
        <img className="w-5 h-auto m-3" src={icon} alt={`${text}ButtonIcon`} />
        {text}
      </button>
    </div>
  );
}

export function Menu() {
  const history = useHistory();
  const location = useLocation();
  const locale = useLocaleContext();
  return (
    <div
      className="fixed h-full w-60 bg-gray-50"
      style={{
        minWidth: "240px",
      }}
    >
      <div className="flex flex-col h-full">
        <p className="m-5 text-2xl font-extrabold">Postme</p>
        <div>
          <MenuItem
            icon={newIcon}
            text={locale.menu.newPost}
            handleClick={() => {
              newPostEditorIsOpened$.next(true);
              logEvent("Add");
            }}
          />
          <MenuItem
            icon={postsIcon}
            text={locale.menu.posts}
            isMatch={location.pathname === "/"}
            handleClick={() => history.push("/")}
          />
          <MenuItem
            icon={profileIcon}
            text={locale.menu.profile}
            isMatch={location.pathname === "/profile"}
            handleClick={() => {
              history.push("/profile");
              logEvent("Profile");
            }}
          />
        </div>
        <div className="flex-grow" />
        <MenuItem
          icon={exitIcon}
          text={locale.menu.exit}
          handleClick={() => {
            logEvent("Exit");
            window.location.replace(`${config.API_URL}/auth/logout`);
          }}
        />
      </div>
    </div>
  );
}
