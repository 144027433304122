import React, { useEffect, useContext } from "react";
import LocaleContext from "../../locales";
import config from "../../config";
import { logEvent } from "../../state/amplitude";
import appleSvg from "../../assets/login/apple.svg";
import googleSvg from "../../assets/login/google.svg";

export function SignIn() {
  useEffect(() => {
    logEvent("Login");
  }, []);
  const locale = useContext(LocaleContext);
  return (
    <div className="flex flex-col items-center mx-3">
      <h2 className="mt-40 text-center text-3xl leading-9 font-extrabold text-gray-900">
        Postme
      </h2>
      <p className="uppercase mt-3 text-center text-sm leading-5 text-gray-400">
        {locale.signIn.enter}
      </p>
      <button
        onClick={() => {
          localStorage.setItem("isLoginButtonClicked", true);
          window.location.replace(`${config.API_URL}/auth/google`);
        }}
        type="submit"
        style={{
          maxWidth: "336px",
          height: "52px",
        }}
        className="mt-12 group relative w-full flex items-center justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-black bg-gray-200 hover:bg-gray-300 focus:outline-none active:bg-gray-400 transition duration-150 ease-in-out"
      >
        <img className="mr-2" src={googleSvg} alt="google" />
        {locale.signIn.google}
      </button>
      <button
        onClick={() => {
          localStorage.setItem("isLoginButtonClicked", true);
          window.location.replace(`${config.API_URL}/auth/apple`);
        }}
        type="submit"
        style={{
          maxWidth: "336px",
          height: "52px",
        }}
        className="mt-3 group relative w-full flex items-center justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-black bg-gray-200 hover:bg-gray-300 focus:outline-none active:bg-gray-400 transition duration-150 ease-in-out"
      >
        <img
          style={{
            marginTop: "-4px",
          }}
          className="mr-2"
          src={appleSvg}
          alt="apple"
        />
        {locale.signIn.apple}
      </button>
      <p className="max-w-xs mt-11 text-sm text-center text-gray-600">
        {locale.signIn.policy1}{" "}
        <a
          className="underline hover:text-gray-400"
          href="https://postmeapp.ru/pp-web"
          rel="noopener noreferrer"
          target="_blank"
        >
          {locale.signIn.policy2}
        </a>{" "}
        {locale.signIn.policy3}{" "}
        <a
          className="underline hover:text-gray-400"
          href="https://postmeapp.ru/tnc-web"
          rel="noopener noreferrer"
          target="_blank"
        >
          {locale.signIn.policy4}
        </a>
      </p>
    </div>
  );
}
