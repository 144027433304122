import React, { useEffect } from "react";
import { useEventCallback, useObservable } from "rxjs-hooks";
import { of } from "rxjs";
import { locale$, useLocaleContext } from "../../locales";
import { items$ } from "../../state/items";
import { logEvent } from "../../state/amplitude";
import {
  newPostEditorIsOpened$,
  updatePostEditorIsOpened$,
  updatePostId$,
  updatePostText$,
  updatePostImages$,
} from "../../components/Editor/Editor";
import { Media } from "../../components/Media/Media";
import { Spinner } from "../../components/Spinner/Spinner";
import { withPending } from "../../utils/rx";
import { readFile } from "../../utils";
import config from "../../config";
import plusIcon from "../../assets/posts/plus.svg";
import { mergeMap, tap } from "rxjs/operators";

export const getItems = () =>
  withPending(fetch(`${config.API_URL}/items`).then((res) => res.json())).pipe(
    tap((res) => {
      if (res && res.result && res.result.data) {
        items$.next(res.result.data);
      }
    })
  );

const withDots = (text, maxLength = 120) =>
  text.length < maxLength ? text : `${text.slice(0, maxLength)}…`;

export function Post({ item }) {
  const locale = useLocaleContext();
  const currentLocale = useObservable(() => locale$, "en");
  const formatter = new Intl.DateTimeFormat(currentLocale, {
    hourCycle: "h23",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
  const [deleteCallback, deleteRes] = useEventCallback((event$) =>
    event$.pipe(
      mergeMap((id) =>
        withPending(
          fetch(`${config.API_URL}/items/${id}`, {
            method: "delete",
          }).then((res) => res.json())
        ).pipe(
          mergeMap((res) => {
            if (res && res.result && res.result.status === "success") {
              logEvent("List_Delete", {
                itemId: id,
              });
              return getItems();
            }
            return of(res);
          })
        )
      )
    )
  );

  const [editCallback, editRes] = useEventCallback((event$) =>
    event$.pipe(
      mergeMap((item) =>
        withPending(
          Promise.all(
            ((val) => (val ? JSON.parse(val) : []))(item.media).map(
              async ({ fileName, mimetype }) => {
                const file = await fetch(`${config.API_URL}/static/${fileName}`)
                  .then((r) => r.blob())
                  .then(
                    (blobFile) =>
                      new File([blobFile], fileName.slice(37), {
                        type: mimetype,
                      })
                  );
                return {
                  ...(await readFile(file)),
                };
              }
            )
          )
        ).pipe(
          tap((res) => {
            if (res && res.done) {
              logEvent("List_Open", {
                itemId: item.id,
              });
              updatePostId$.next(item.id);
              updatePostText$.next(item.text);
              updatePostImages$.next(res.result);
              updatePostEditorIsOpened$.next(true);
            }
          })
        )
      )
    )
  );

  return (
    <div
      className="bg-gray-50 border rounded-md border-opacity-5 hover:bg-white p-4 text-sm w-80 h-44 flex flex-col focus:outline-none cursor-pointer"
      onClick={() => {
        if (editRes && editRes.pending) return;
        editCallback(item);
      }}
    >
      <div className="overflow-auto flex flex-grow w-full">
        <div className="whitespace-pre-wrap overflow-ellipsis overflow-hidden text-left flex-grow">
          {(item.text && withDots(item.text)) || locale.post.withoutText}
        </div>
        <Media media={item.media} />
      </div>
      <div className="flex text-gray-400 font-semibold mt-1 focus:outline-none">
        {editRes && editRes.pending ? (
          <div>
            <Spinner classes="text-gray-400" />
          </div>
        ) : null}
        {formatter.format(new Date(item.updated_at))}
        {" • "}
        <button
          disabled={deleteRes && deleteRes.pending}
          className="text-xs hover:underline ml-1"
          id={item.id}
          onClick={(event) => {
            deleteCallback(item.id);
            event.stopPropagation();
          }}
        >
          {locale.post.delete}
        </button>
      </div>
    </div>
  );
}

const byDescCreatedAt = (a, b) => {
  return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
};

export function PostsPure({ items }) {
  const locale = useLocaleContext();
  useEffect(() => {
    logEvent("List");
  }, []);
  return (
    <div className="m-3 flex flex-wrap content-start">
      <div className="m-2">
        <button
          className="bg-gray-50 border rounded-md border-opacity-5 hover:bg-white p-4 text-sm w-80 h-44 flex flex-col focus:outline-none"
          onClick={() => {
            logEvent("List_Add");
            newPostEditorIsOpened$.next(true);
          }}
        >
          <div className="flex flex-col items-center justify-center w-full h-full">
            <img src={plusIcon} alt="plusIcon" />
            <p className="mt-2 font-semibold text-gray-500">
              {locale.posts.newPost}
            </p>
          </div>
        </button>
      </div>
      {items.sort(byDescCreatedAt).map((item, key) => (
        <div key={key} className="m-2">
          <Post item={item} />
        </div>
      ))}
    </div>
  );
}

export function Posts() {
  const items = useObservable(() => items$);
  useObservable(getItems);
  if (!items) return null;
  return <PostsPure items={items} />;
}
