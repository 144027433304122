const locale = {
  editor: {
    newPost: "New post",
    close: "Close",
    yourPostsText: "Your post's text",
    save: "Save",
    delete: "Delete",
  },
  updatePostEditor: {
    instagramPost: "Instagram Post",
  },
  uploader: {
    addMedia: "Add media",
  },
  menu: {
    newPost: "New post",
    posts: "Posts",
    profile: "Profile",
    exit: "Exit",
  },
  profile: {
    myProfile: "My profile",
    language: "Language",
  },
  posts: {
    newPost: "New post",
  },
  post: {
    delete: "Delete",
    withoutTex: "No text",
  },
  signIn: {
    enter: "Login",
    apple: "Continue with Apple",
    google: "Continue with Google",
    policy1: "By logging in, you accept",
    policy2: "Privacy Policy",
    policy3: "and",
    policy4: "Terms and Conditions",
  },
};

export default locale;
