const locale = {
  editor: {
    newPost: "Новый пост",
    close: "Закрыть",
    yourPostsText: "Ваш текст поста",
    save: "Сохранить",
    delete: "Удалить",
  },
  updatePostEditor: {
    instagramPost: "Пост для Instagram",
  },
  uploader: {
    addMedia: "Добавить медиа",
  },
  menu: {
    newPost: "Новый пост",
    posts: "Посты",
    profile: "Профиль",
    exit: "Выйти",
  },
  profile: {
    myProfile: "Мой профиль",
    language: "Язык",
  },
  posts: {
    newPost: "Новый пост",
  },
  post: {
    delete: "Удалить",
    withoutTex: "Без текста",
  },
  signIn: {
    enter: "Вход",
    apple: "Войти через Apple",
    google: "Войти через Google",
    policy1: "Осуществляя вход, вы соглашаетесь с",
    policy2: "Политикой конфиденциальности",
    policy3: "и",
    policy4: "Лицензионным соглашением",
  },
};

export default locale;
