import React from "react";
import { useObservable } from "rxjs-hooks";
import * as R from "ramda";
import { locale$, useLocaleContext } from "../../locales";
import { user$ } from "../../state/user";

export function Profile() {
  const user = useObservable(() => user$);
  const locale = useLocaleContext();
  const currentLocale = useObservable(() => locale$);
  return (
    <div className="mx-7 my-5">
      <p style={{ fontSize: "26px" }} className="font-extrabold">
        {locale.profile.myProfile}
      </p>
      <p className="mt-4 text-lg">
        {R.path(["result", "data", "email"], user)}
      </p>
      <p style={{ fontSize: "26px" }} className="mt-7 font-extrabold">
        {locale.profile.language}:
      </p>
      <div className="mt-3">
        <input
          checked={currentLocale === "ru"}
          onChange={() => {
            locale$.next("ru");
          }}
          className="ml-1 transform scale-150"
          type="radio"
          id="ru"
          name="language"
          value="ru"
        />
        <label className="ml-3 text-lg" htmlFor="ru">
          <span role="img" aria-label="ru">
            🇷🇺
          </span>{" "}
          Русский
        </label>
      </div>
      <div className="mt-1">
        <input
          checked={currentLocale === "en"}
          onChange={() => {
            locale$.next("en");
          }}
          className="ml-1 transform scale-150"
          type="radio"
          id="en"
          name="language"
          value="en"
        />
        <label className="ml-3 text-lg" htmlFor="en">
          <span role="img" aria-label="ru">
            🇬🇧
          </span>{" "}
          English
        </label>
      </div>
    </div>
  );
}
