import { v4 } from "uuid";
import amplitude from "amplitude-js";
import moment from "moment";
import config from "../config";
import { filter, tap } from "rxjs/operators";
import { subscriptions$, user$ } from "./user";
import { items$ } from "./items";
import {
  newPostEditorIsOpened$,
  updatePostEditorIsOpened$,
} from "../components/Editor/Editor";

const project = amplitude.getInstance();
project.init(config.AMPLITUDE_API_KEY);
project.setUserId(v4());

export const logEvent = (...args) => project.logEvent(...args);

user$
  .pipe(
    filter((item) => item && item.result && item.result.data),
    tap((item) => {
      project.setUserId(item.result.data.id);
      project.setUserProperties({
        Email: item.result.data.email,
        Сohort_day: moment(item.result.data.created_at).dayOfYear(),
        Сohort_week: moment(item.result.data.created_at).week(),
        Сohort_month: moment(item.result.data.created_at).month() + 1,
        Сohort_yeah: moment(item.result.data.created_at).year(),
        Cohort_date: moment(item.result.data.created_at).format("DD.MM.YYYY"),
      });
      logEvent("Session_start");
      if (!item.result.hasLoggedIn) {
        logEvent("Login_first_time");
      }
      if (localStorage.getItem("isLoginButtonClicked")) {
        localStorage.removeItem("isLoginButtonClicked");
        if (item.result.data.provider === "google")
          return logEvent("Login_Google_success");
        return logEvent("Login_Apple_success");
      }
    })
  )
  .subscribe();

subscriptions$
  .pipe(
    filter((item) => item && item.result && item.result.data),
    tap((item) => {
      project.setUserProperties({
        Subscription_Apple:
          item.result.data.receipts.length &&
          new Date(item.result.data.receipts[0].expires_date_ms) > new Date()
            ? "yes"
            : "no",
        Apple_Exp_date: item.result.data.receipts.length
          ? item.result.data.receipts[0].expires_date_ms
          : "no",
        Apple_Period: item.result.data.receipts.length
          ? item.result.data.receipts[0].product_id
          : "no",
        Subscription_Google:
          item.result.data.purchase_tokens.length &&
          new Date(item.result.data.purchase_tokens[0].expiry_time_millis) >
            new Date()
            ? "yes"
            : "no",
        Google_Exp_date: item.result.data.purchase_tokens.length
          ? item.result.data.purchase_tokens[0].expiry_time_millis
          : "no",
        Google_Period: item.result.data.purchase_tokens.length
          ? item.result.data.purchase_tokens[0].product_id
          : "no",
      });
    })
  )
  .subscribe();

items$.subscribe((items) => {
  project.setUserProperties({
    Count_posts: items.length,
  });
});

newPostEditorIsOpened$.subscribe((isOpened) => {
  if (isOpened) return logEvent("Editor_New");
  return logEvent("List");
});

updatePostEditorIsOpened$.subscribe((isOpened) => {
  if (isOpened) return logEvent("Editor");
  return logEvent("List");
});
